import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import validator from "validator";
import { Link } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo, useState, useEffect, Fragment, useRef } from "react";
import { v4 as uuid } from "uuid";
import { Formik } from 'formik';
import * as Yup from 'yup' 

import useStyles from "../../../components/styles/profissional.styles";
import "../../../components/styles/professionalEditar.styles.css";
import imgAp from "../../../assets/img/imgLogin.jpg";

import Footer from "../../../components/footer";
import Header from "../../../components/header";
import MenuPerfil from "../../../components/menuPerfil"; 
import { Checkbox as StyledCheckbox } from "../../../components/checkbox";
import { CircularProgress } from "../../../components/circularProgress";
import { editProfessionalSchema } from "../../../validations/edit-professional-schema";
import { useParams } from "../../../hooks/useParams";
import { useScrollbar } from "../../../hooks/useScrollbar"; 

import * as AttendenceMethodsServices from "../../../services/api/Attendence-methodsServices";
import * as AttendencePlataformsServices from "../../../services/api/Attendence-plataformsServices";
import * as InsurancesServices from "../../../services/api/InsurancesServices";
import * as ProfessionalsServices from "../../../services/api/ProfessionalsServices";
import * as SpecialitiesServices from "../../../services/api/SpecialitiesServices";

import Swal from 'sweetalert2';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import axios from "axios";

const profFormation = [
  {
    label: "FONOAUDIOLOGIA",
    value: "FONOAUDIOLOGIA",
    name: "formation",
  },
  {
    label: "PSICOLOGIA",
    value: "PSICOLOGIA",
    name: "formation",
  },
  {
    label: "PEDAGOGIA",
    value: "PEDAGOGIA",
    name: "formation",
  },
  {
    label: "FISIOTERAPIA",
    value: "FISIOTERAPIA",
    name: "formation",
  },
];
const profTitle = [
  {
    label: "GRADUAÇÃO",
    value: "GRADUAÇÃO",
    name: "title",
  },
  {
    label: "ESPECIALIZAÇÃO",
    value: "ESPECIALIZAÇÃO",
    name: "title",
  }, 
  {
    label: "MESTRADO",
    value: "MESTRADO",
    name: "title",
  },
  {
    label: "DOUTORADO",
    value: "DOUTORADO",
    name: "title",
  },
  {
    label: "PÓS-DOUTORADO",
    value: "POS-DOUTORADO",
    name: "title",
  },
];

export default function EditarProfissional() { 
  const { routeParams } = useParams();
  const { hideScrollBar, showScrollBar } = useScrollbar();
  const classes = useStyles();

  const professionalId = useMemo(() => routeParams?.pop(), [routeParams]);
  const dialogRef = useRef(null);

  const [form, setForm] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [professionalSpecialities, setProfessionalSpecialities] = useState([]);

  const [image, setImage] = useState("");
  const [imageForm, setImageForm] = useState("");

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [cpfCnpj, setCpfCnpj] = useState("");
  const [mask, setMask] = useState("");

  const [fileUrl, setFileUrl] = useState("");


  const handleOnSubmit = async (data) => {
    const attendanceMethods =
      values?.attendanceMethods?.map((el) => {
        return {
          attendenceMethodId: el.attendenceMethodId || el.attendenceMethod.id,
          professionalId: professionalId,
          period: el?.period || [],
          value: el.value,
          schedule: el.schedule,
        };
      }) || [];

    const profileData = {
      clinicAddresses: data.clinicAddresses,
      crfa: data.crfa,
      description: data.description,
      email: data.email,
      formation: data.formation,
      insurances: data.insurance,
      methods: [],
      name: data.name,
      phone: data.phone,
      picture: fileUrl || image || data.picture,
      title: data.title,
      whatsapp: data.whatsapp,
      platforms: data.platforms,
      speacilities: data.speciality,
      cpf_cnpj: data.cpf_cnpj,
    };

    const qualifications =
      values?.qualifications?.map((q) => {
        return {
          specialityId: q.specialityId,
          professionalId,
          professionalAttendenceMethodId: q?.professionalAttendenceMethodId,
          type: q?.type,
        };
      }) || [];

    hideScrollBar();
    dialogRef.current?.showModal();
    setIsSubmitting(true);


    await ProfessionalsServices.updateProfile(profileData).then(
      () => { 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
        Toast.fire({
          icon: 'success',
          title: 'Perfil atualizado!',
        });
 
      },
      () => { 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
        Toast.fire({
          icon: 'error',
          title: 'Existem campos nulos',
        });
      }
    );

    attendanceMethods?.forEach(async (atd) => {
      await ProfessionalsServices.postProfessionalAttendenceMethods(atd).then(
        () => {
          qualifications?.forEach(async () => {});
        }
      );
    });

    setIsSubmitting(false);
    dialogRef.current?.close();
    showScrollBar();
  };

  const {
    values,
    handleChange,
    handleSubmit: onSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...form,
      insurances: [],
    },
    enableReinitialize: true,
    validationSchema: editProfessionalSchema,
    onSubmit: handleOnSubmit,
  }); 
 
  // Remove formatting and re-format after.
  // Necessary to resolve bugs with formatting and auto-complete
  const ajusta = v => {
      const digitos = !v ? '' : v.replace(/[^\d]/g, '');
      if (!digitos || digitos.length < 10) return v;
      const corte = digitos.length === 10 ? 6 : 7;
      const max = digitos.length > 11 ? 11 : digitos.length;
      return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
  }

  const maskBuilder = v => {
      if (!v || v.length === 0) return '';
      const a = ajusta(v);
      return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
  }

  const handleChangeSelect = (e) => { 
    setFieldValue(e.name, e.value, ajusta(e.value));
  };

  const handleChangeInsurances = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("insurancesCheck", [
        ...values.insurancesCheck,
        {
          insurance: {
            id: value,
            name: label,
          },
          insuranceId: value,
          professionalId,
        },
      ]);
    } else {
      setFieldValue(
        "insurancesCheck",
        values.insurancesCheck.filter((insu) => insu.insurance.id !== value)
      );
    }
    handleChange(e);
  };
  const handleChangeSpecialities = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("specialitiesCheck", [
        ...values.specialitiesCheck,
        {
          speciality: {
            id: value,
            name: label,
          },
          specialityId: value,
          professionalId,
        },
      ]);
    } else {
      setFieldValue(
        "specialitiesCheck",
        values.specialitiesCheck.filter((insu) => insu.speciality.id !== value)
      );
    }
    handleChange(e);
  };
  const handleChangeAttendance = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("attendanceMethods", [
        ...values.attendanceMethods,
        {
          attendenceMethod: {
            id: value,
            name: label,
          },
          attendenceMethodId: value,
          period: [],
          professionalId,
          schedule: "",
          value: "",
        },
      ]);

      setFieldValue("qualifications", [
        ...values.qualifications,
        {
          specialityId: [],
          professionalId,
          professionalAttendenceMethodId: value,
        },
      ]);

      setFieldValue("professionalSpecialities", [
        ...values.professionalSpecialities,
        {
          specialityId: [],
          professionalId,
          professionalAttendenceMethodId: value,
        },
      ]);
    } else {
      setFieldValue(
        "attendanceMethods",
        values.attendanceMethods.filter(
          (atd) => atd.attendenceMethod.id !== value
        )
      );
      setFieldValue(
        "insurances",
        values.insurances.filter((insu) => insu.insurance.id !== value)
      );

      setFieldValue(
        "qualifications",
        values.qualifications.filter(
          (qualy) => qualy.professionalAttendenceMethodId !== value
        )
      );

      setFieldValue(
        "professionalSpecialities",
        values.professionalSpecialities.filter(
          (qualy) => qualy.professionalAttendenceMethodId !== value
        )
      );
    }

    handleChange(e);
  };

  const handleFile = (e) => {
  setImage(URL.createObjectURL(e.target.files[0]));
  setImageForm(e.target.files[0]);

  const formData = new FormData();
  formData.append("fileToUpload", e.target.files[0]);

  const endpoint = "https://files.minhafono.com.br/upload.php";
  axios.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    const uploadedFileUrl = res.data.file_url;
    setFileUrl(uploadedFileUrl);

    Swal.fire({
      icon: 'success',
      title: 'Foto atualizada!',
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }).catch(() => {
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar a imagem!',
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  });
};


  const validateEmail = (e) => {
    var email = e.target.value;

    if (!validator.isEmail(email)) {
      setFieldError("email", "E-mail inválido.");
    } else {
      setFieldError("email", undefined);
    }

    handleChange(e);
  };

  // eslint-disable-next-line no-unused-vars
  const [attendenceMethodsCheckbox, setAttendenceMethodsCheckbox] = useState(
    []
  );

  // eslint-disable-next-line no-unused-vars
  const [clinicAddresses, setClinicAddresses] = useState([]);

  let atmIds = [];

  useEffect(() => {
    (async () => {
      try {
        hideScrollBar();
        dialogRef.current?.showModal();
        const resp = await ProfessionalsServices.getProfile();
        const respAtt = await ProfessionalsServices.getAttendenceMethods();
        const professionalSpec =
          await ProfessionalsServices.getProfessionalSpecialities(resp.data.id);
        setProfessionalSpecialities(professionalSpec.data);
        setAttendenceMethod(respAtt.data);
        setForm({
          ...resp.data,
          attendanceMethods: resp.data.professionalAttendenceMethods.map(
            (el) => {
              return {
                attendenceMethod: el.attendenceMethod,
                attendenceMethodId: el.attendenceMethod.id,
                period: JSON.parse(el?.period) || [],
                professionalId,
                schedule: el.schedule || "",
                value: el.value || "",
              };
            }
          ),
          insurancesCheck: resp.data.professionalInsurances.map((el) => {
            return {
              insurance: el.insurance,
              insuranceId: el.insurance.id,
              professionalId,
            };
          }),
          specialitiesCheck: resp.data.professionalSpecialities.map((el) => {
            return {
              speciality: el.speciality,
              specialityId: el.id,
              professionalId,
            };
          }),
          speciality: resp.data.professionalSpecialities.map(
            (atm) => atm.speciality.id
          ),
          attendance: resp.data.professionalAttendenceMethods.map(
            (atm) => atm.attendenceMethod.id
          ),
          insurance: resp.data.professionalInsurances.map(
            (insu) => insu.insurance.id
          ),
        });
        setImage(resp?.data?.picture);
        setClinicAddresses(resp.data.clinicAddresses);

        resp.data.professionalAttendenceMethods.forEach((atm) =>
          atmIds.push(atm.attendenceMethod.id)
        );
        setAttendenceMethodsCheckbox(atmIds);
      } catch {  
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
        Toast.fire({
          icon: 'error',
          title: 'Erro ao retornar dados!',
        });
      } finally {
        dialogRef.current?.close();
        showScrollBar();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addClinicHandler = async () => {
    hideScrollBar();
    dialogRef.current?.showModal();

    await ProfessionalsServices.addClinic(form.id).then(
      () => {
        console.log(values.clinicAddresses);
        setFieldValue("clinicAddresses", [
          ...values.clinicAddresses,
          {
            id: uuid(),
            updatedAt: "",
            deletedAt: null,
            version: 1,
            clinic_name: "",
            clinic_contact: "",
            street: "",
            number: "",
            zipcode: "",
            city: "",
            state: "",
            district: "",
            complement: null,
          },
        ]);
      },
      () => {}
    );

    dialogRef.current?.close();
    showScrollBar();
  };

  const removeClinicHandler = async (id) => {
    hideScrollBar();
    dialogRef.current?.showModal();
    await ProfessionalsServices.removeClinic(id).then(
      () => {
        setFieldValue(
          "clinicAddresses",
          values.clinicAddresses.filter((c) => c.id !== id)
        );
      },
      () => {}
    );
    dialogRef.current?.close();
    showScrollBar();
  };

  const [specialities, setSpecialities] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await SpecialitiesServices.get();
      setSpecialities(resp.data);
    })();
  }, []);

  const [attendenceMethod, setAttendenceMethod] = useState([]);

  const [insurances, setInsurances] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await InsurancesServices.get();
      setInsurances(resp.data);
    })();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [attendencePlataforms, setAttendencePlataforms] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await AttendencePlataformsServices.get();
      setAttendencePlataforms(resp.data);
    })();
  }, []);

  const [attendenceMethods, setAttendenceMethods] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await AttendenceMethodsServices.get();
      setAttendenceMethods(resp.data);
    })();
  }, []);

  const checkCEP = (e, key) => {
    const cep = e.target.value.replace(/\D/g, "");
    if (!e.target.value) return;
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then(
        (addressCEP) => {
          setFieldValue(`clinicAddresses[${key}].state`, addressCEP.uf);
          setFieldValue(`clinicAddresses[${key}].district`, addressCEP.bairro);
          setFieldValue(
            `clinicAddresses[${key}].street`,
            addressCEP.logradouro
          );
          setFieldValue(`clinicAddresses[${key}].city`, addressCEP.localidade);
        },
        () => {}
      );
  };

  const {
    input,
    linkEditar,
    titulo,
    newGrid,
    menu,
    perfil,
    page,
    imagem,
    submit,
    subtitulo,
    typographyTitulo,
    typographySubtitulo  } = classes;

  useEffect(() => {}, [values]);

  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      <div style={{ paddingBottom: "8px" }}>
        <Grid container className={newGrid}>
          <Grid item md={3} className={menu}>
            <MenuPerfil />
          </Grid>
          <Grid item md={9} className={perfil}>
            <Grid className={page}>
              <Grid item md={9} xs={7}>
                <Typography className={titulo}>
                  {" "}
                  FICHA DE PROFISSIONAL
                </Typography>
              </Grid>
              <Grid item md={3} xs={5}></Grid>
            </Grid>
            <Formik
            validationSchema={Yup.object().shape({
              crfa: Yup.string() 
                  .required("O Número de Conselho de Classe é obrigatório.") 
              })}
            >
            {({
                errors, 
                touched, 
                handleBlur
              }) => (
              <form  onSubmit={onSubmit}> 
                <Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{ textAlign: "center", marginTop: "35px" }}
                    >
                      {image ? (
                        <img
                          className={imagem}
                          src={image}
                          alt="Imagem Professional"
                          width="150"
                          height="150"
                        />
                      ) : (
                        <img
                          src={imgAp}
                          className={imagem}
                          alt="Imagem"
                          width="150"
                          height="150"
                        />
                      )}
                      <br />
                      <input
                        accept="image/*"
                        className={input}
                        name="picture"
                        onChange={(e) => handleFile(e)}
                        id="contained-button-file" 
                        type="file"
                      />
                      <label htmlFor="contained-button-file">
                        <Link
                          color="primary"
                          component="span"
                          className={linkEditar}
                          style={{ cursor: "pointer" }}
                        >
                          <EditIcon /> Editar Foto
                        </Link>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={9} style={{ marginTop: 10 }}>
                      <Typography className={typographySubtitulo}>
                        NOME COMPLETO
                      </Typography>
                      <TextField
                        fullWidth
                        id="name"
                        type="text"
                        value={values?.name}
                        name="name"
                        autoComplete="name"
                        helperText={errors.name && <p>{errors.name}</p>}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                      />

                      <Typography className={typographySubtitulo}>
                        NÚMERO DE CONSELHO DE CLASSE
                      </Typography>
                      <TextField
                        fullWidth
                        id="crfa"
                        type="text"
                        value={values?.crfa} 
                        error={Boolean(touched.crfa && errors.crfa)}
                        helperText={touched.crfa && errors.crfa}
                        name="crfa"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                        required
                      />

                      <Typography className={typographySubtitulo}>
                              CPF/CNPJ *
                            </Typography>
                            <CpfCnpj
                            fullWidth
                              id="cpf_cnpj"
                              type="text"  
                              error={Boolean(touched.cpf_cnpj && errors.cpf_cnpj)}
                              helperText={touched.cpf_cnpj && errors.cpf_cnpj}
                              name="cpf_cnpj"
                              value={values?.cpf_cnpj} 
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              style={{width: '100%',
    backgroundColor: '#f8f8fa',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderBottomColor: '#cbcbce'}}
                               
                            />
                    </Grid>
                  </Grid>

                  {/* Curriculo */}

                  <Typography className={typographyTitulo}>CURRÍCULO</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={typographySubtitulo}>
                        FORMAÇÃO ACADÊMICA
                      </Typography>
                      <Select
                        placeholder="Selecione"
                        id="formation"
                        name="formation"
                        options={profFormation}
                        isSearchable={false}
                        onChange={handleChangeSelect}
                        value={profFormation.find(
                          (profTitle) => profTitle.value === values?.formation
                        )}
                        required
                      />
                      <Typography className={typographySubtitulo}>
                        TITULAÇÃO MÁXIMA
                      </Typography>
                      <Select
                        placeholder="Selecione"
                        id="title"
                        name="title"
                        options={profTitle}
                        isSearchable={false}
                        onChange={handleChangeSelect}
                        value={profTitle.find(
                          (profTitle) => profTitle.value === values?.title
                        )}
                        required
                      />
                      <Typography className={typographySubtitulo}>
                        INFORMAÇÕES ADICIONAIS
                      </Typography>
                      <TextField
                        multiline={true}
                        maxRows={5}
                        inputProps={{ maxLength: 500 }}
                        fullWidth
                        id="description"
                        name="description"
                        value={values?.description}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                      />
                      <Grid container spacing={3}></Grid>
                    </Grid>
                  </Grid>
                  {/* Contato */}

                  <Typography className={typographyTitulo}>CONTATO</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography className={typographySubtitulo}>
                        TELEFONE
                      </Typography>

                      <InputMask
                        maskPlaceholder="(00) 0000-0000"
                        mask="(99) 9999-9999"
                        fullWidth
                        id="phone"
                        name="phone"
                        label="(00) 0000-0000"
                        value={values?.phone}
                        type="tel"
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                      >
                        {() => (
                          <TextField
                            name="phone"
                            style={{ marginTop: "16px", width: "100%" }}
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={typographySubtitulo}>
                        WHATSAPP
                      </Typography>
                      <InputMask
                        placeholder="(00) 00000-0000"
                        mask="(99) 99999-9999"
                        fullWidth
                        id="whatsapp"
                        name="whatsapp"
                        value={values?.whatsapp}
                        type="tel"
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                        required
                      >
                        {() => (
                          <TextField
                            name="whatsapp"
                            style={{ marginTop: "16px", width: "100%" }}
                            required
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography className={typographySubtitulo}>
                        E-MAIL PROFISSIONAL
                      </Typography>
                      <TextField
                        placeholder="seuemail@email.com"
                        fullWidth
                        id="email"
                        name="email"
                        value={values?.email}
                        type="email"
                        onChange={(e) => validateEmail(e)}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {errors?.email}
                      </span>
                    </Grid>
                  </Grid>

                  {/* Antedimento */}

                  <Typography className={typographyTitulo}>
                    ATENDIMENTO
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={typographySubtitulo}>
                        QUERO ATENDER
                      </Typography>
                      <Typography className={subtitulo}>
                        Selecione a opção de como gostaria de atender os seus
                        pacientes
                      </Typography>
                    </Grid>
                    {attendenceMethods.map((data) => (
                      <Grid item key={data.id} xs={12} sm={3}>
                        <FormGroup>
                          <StyledCheckbox
                            checked={values?.attendance?.includes(data.id)}
                            value={data.id}
                            onChange={(e) => handleChangeAttendance(e, data.name)}
                            name="attendance"
                            label={data.name}
                            id={data.id}
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={typographySubtitulo}>
                        ACEITA CONVÊNIO?
                      </Typography>
                      <Typography className={subtitulo}>
                        Se sim, liste os convênios aceitos
                      </Typography>
                    </Grid>
                    {insurances
                      .sort(function (a, b) {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((data) => (
                        <Grid item key={data.id} xs={6} sm={3}>
                          <FormGroup>
                            <StyledCheckbox
                              checked={values?.insurance?.includes(data.id)}
                              value={data.id}
                              onChange={(e) =>
                                handleChangeInsurances(e, data.name)
                              }
                              name="insurance"
                              label={data.name}
                              id={data.id}
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                  </Grid>
                  {values?.attendanceMethods?.map((data, index) => (
                    <Grid
                      container
                      spacing={3}
                      style={{
                        borderStyle: "outset",
                        borderColor: "#54B5CF",
                        BorderRadius: "5px",
                        borderRadius: "10px",
                        margin: "10px 0px",
                        padding: "10px 20px",
                      }}
                    >
                      <Grid item xs={12} sm={12}>
                        <Typography
                          className={typographySubtitulo}
                          style={{ textAlign: "center" }}
                        >
                          ATENDIMENTO {data.attendenceMethod.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={typographySubtitulo}
                          style={{ textAlign: "center" }}
                        >
                          VALOR (R$)
                        </Typography>
                        <TextField
                          fullWidth
                          id={`attendanceMethods[${index}].value`}
                          name={`attendanceMethods[${index}].value`}
                          value={values?.attendanceMethods[index]?.value}
                          onChange={handleChange}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={typographySubtitulo}
                          style={{ textAlign: "center" }}
                        >
                          DURAÇÃO (Minutos)
                        </Typography>
                        <NumberFormat
                          customInput={TextField}
                          fullWidth
                          id={`attendanceMethods[${index}].schedule`}
                          name={`attendanceMethods[${index}].schedule`}
                          value={values?.attendanceMethods[index]?.schedule}
                          onChange={handleChange}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={{ justifyContent: "center" }}
                      >
                        <Grid item xs={12} sm={12}>
                          <Typography
                            className={typographySubtitulo}
                            style={{ textAlign: "center" }}
                          >
                            HORÁRIOS DISPONÍVEIS
                          </Typography>
                        </Grid>

                        {[
                          { value: "Morning", label: "Manhã" },
                          { value: "Afternoon", label: "Tarde" },
                          { value: "Night", label: "Noite" },
                        ].map((el) => (
                          <Grid key={`${el.value}.${index}`} item xs={6} sm={3}>
                            <FormGroup>
                              <StyledCheckbox
                                checked={values?.attendanceMethods?.[
                                  index
                                ]?.period?.includes(el.value)}
                                id={`${el.value}.${index}`}
                                name={`attendanceMethods[${index}].period`}
                                onChange={handleChange}
                                {...el}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}

                  {values?.attendance?.map((atd, index) => {
                    return (
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <Typography className={typographySubtitulo}>
                            ESPECIALIDADE PARA{" "}
                            {attendenceMethod
                              ?.find((data) => data.id === atd)
                              ?.name?.toUpperCase() === "TELEATENDIMENTO"
                              ? "TELEATENDIMENTOS"
                              : "ATENDIMENTOS PRESENCIAIS"}
                          </Typography>
                        </Grid>
                        {specialities
                          .sort(function (a, b) {
                            if (a.name < b.name) {
                              return -1;
                            }
                            if (a.name > b.name) {
                              return 1;
                            }
                            return 0;
                          })
                          // eslint-disable-next-line array-callback-return
                          .map((data, key) => {
                            if (
                              data.attendenceMethod?.id.toString() ===
                              atd.toString()
                            ) {
                              return (
                                <Grid item key={data.id} xs={6} sm={3}>
                                  <FormGroup>
                                    <StyledCheckbox
                                      checked={values?.speciality?.includes(
                                        data.id
                                      )}
                                      onChange={handleChangeSpecialities}
                                      value={data.id}
                                      name="speciality"
                                      label={data.name}
                                      id={`${data.id}.${index}.${key}`}
                                    />
                                  </FormGroup>
                                </Grid>
                              );
                            } else {
                            }
                          })}
                      </Grid>
                    );
                  })}

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={typographyTitulo}>
                        INFORMAÇÕES DO CONSULTÓRIO
                      </Typography>
                    </Grid>
                    {values?.clinicAddresses?.map((data, key) => (
                      <Fragment key={data.id}>
                        <Grid
                          key={data.id}
                          container
                          spacing={3}
                          style={{
                            borderStyle: "outset",
                            borderColor: "#54B5CF",
                            BorderRadius: "5px",
                            borderRadius: "10px",
                            margin: "10px 0",
                            paddingBottom: "30px",
                          }}
                        >
                          <Grid item md={6} xs={6}>
                            <Typography className={typographySubtitulo}>
                              NOME DO CONSULTÓRIO
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].clinic_name`}
                              name={`clinicAddresses[${key}].clinic_name`}
                              value={values?.clinicAddresses[key]?.clinic_name}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Typography className={typographySubtitulo}>
                              CONTATO CONSULTÓRIO
                            </Typography>  
                            <InputMask
                              mask={maskBuilder(values?.clinicAddresses[key]?.clinic_contact)}
                              fullWidth
                              id={`clinicAddresses[${key}].clinic_contact`}
                              name={`clinicAddresses[${key}].clinic_contact`}
                              value={values?.clinicAddresses[key]?.clinic_contact} 
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            >
                              {() => (
                                <TextField
                                  name={`clinicAddresses[${key}].clinic_contact`}
                                  value={values?.clinicAddresses[key]?.clinic_contact}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Typography className={typographySubtitulo}>
                              CEP
                            </Typography>
                            <InputMask
                              mask="99999-999"
                              fullWidth
                              id={`clinicAddresses[${key}].zipcode`}
                              name={`clinicAddresses[${key}].zipcode`}
                              value={values?.clinicAddresses[key]?.zipcode}
                              onBlur={(e) => checkCEP(e, key)}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            >
                              {() => (
                                <TextField
                                  name={`clinicAddresses[${key}].zipcode`}
                                  value={values?.clinicAddresses[key]?.zipcode}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                  required
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={typographySubtitulo}>
                              ENDEREÇO
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].street`}
                              name={`clinicAddresses[${key}].street`}
                              value={values?.clinicAddresses[key]?.street}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography className={typographySubtitulo}>
                              NÚMERO
                            </Typography>
                            <NumberFormat
                              customInput={TextField}
                              fullWidth
                              id={`clinicAddresses[${key}].number`}
                              name={`clinicAddresses[${key}].number`}
                              value={values?.clinicAddresses[key]?.number}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={typographySubtitulo}>
                              BAIRRO
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].district`}
                              name={`clinicAddresses[${key}].district`}
                              value={values?.clinicAddresses[key]?.district}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={typographySubtitulo}>
                              COMPLEMENTO
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].complement`}
                              name={`clinicAddresses[${key}].complement`}
                              value={values?.clinicAddresses[key]?.complement}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={typographySubtitulo}>
                              ESTADO
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].state`}
                              name={`clinicAddresses[${key}].state`}
                              value={values?.clinicAddresses[key]?.state}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              disabled="false"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={typographySubtitulo}>
                              CIDADE
                            </Typography>
                            <TextField
                              fullWidth
                              id={`clinicAddresses[${key}].city`}
                              name={`clinicAddresses[${key}].city`}
                              value={values?.clinicAddresses[key]?.city}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              disabled="false"
                              required
                            />
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              placeContent: "center",
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#ff5e8f",
                                borderColor: "#54B5CF",
                                borderRadius: "25px",
                                color: "#fff",
                                padding: "15px",
                                margin: "10px 0",
                              }}
                              className={classes.botaoPrimeiro}
                              onClick={() =>
                                removeClinicHandler(
                                  values?.clinicAddresses[key].id
                                )
                              }
                            >
                              REMOVER CONSULTORIO
                            </Button>
                          </div>
                        </Grid>
                      </Fragment>
                    ))}
                    {/* Fim */}
                  </Grid>
                  <Button
                    style={{
                      backgroundColor: "#54B5CF",
                      borderColor: "#54B5CF",
                      borderRadius: "25px",
                      color: "#fff",
                      padding: "15px",
                      margin: "20px 0",
                    }}
                    className={classes.botaoPrimeiro}
                    onClick={addClinicHandler}
                  >
                    Adicionar Consultorio
                  </Button>
                  <Grid
                    style={{
                      textAlign: "center",
                      fontSize: 15,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "24px",
                    }}
                  >
                    <button
                      className={submit}
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                        padding: "16px",
                        margin: "0",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                        }}
                      >
                        SALVAR MEUS DADOS
                      </p>
                      {isSubmitting && <CircularProgress />}
                    </button>
                  </Grid>
                </Grid>
              </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
      <Footer /> 
    </div>
  );
}
