/* eslint-disable no-lone-blocks */
import React from "react";
import "../../../components/styles/style.css";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../components/header";
import ImgLogin from "../../../assets/img/imgLogin.jpg";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { userSignIn } from "../../../redux/actions/authActions";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Footer from "../../../components/footer";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import * as ProfessionalsServices from "../../../services/api/ProfessionalsServices";
import Swal from "sweetalert2";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    backgroundImage: `url(${ImgLogin})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  form: {
    marginTop: theme.spacing(5),
    width: "90%",
  },
  paper: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(20, 10),
    "@media (max-width: 500px)": {
      padding: "0px 0px 0px 0px",
      margin: theme.spacing(5, 5),
    },
  },
  submit: {
    margin: theme.spacing(5, 0, 5, 0),
    borderRadius: "30px",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    backgroundColor: "#54B5CF",
    padding: "5px 20px 5px 20px",
    color: "#F8F8FA",
    "&:hover": {
      backgroundColor: "#0facc4",
      textDecoration: "none",
      color: "#fff",
    },
  },
  titulo: {
    color: "#54B5CF",
    fontFamily: "Montserrat",
    fontSize: "35px",
    fontWeight: 800,
    marginBottom: 10,
    "@media (max-width: 500px)": {
      fontSize: "24px",
    },
  },
  textLink: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "18px",
    color: "#54B5CF",
    textDecoration: "underline",
    "&:hover": {
      color: "#0facc4",
    },
  },
  gridLink: {
    display: "flex",
    "@media (max-width: 500px)": {
      display: "grid",
    },
  },
  input: {
    borderWidth: "0 0 1px 0",
    borderRadius: 0,
    padding: 0,
    backgroundColor: "#F8F8FA",
    "&::placeholder": {
      fontSize: 18,
      color: "#C4C4C4",
    },
    " &:focus": {
      backgroundColor: "#F8F8FA",
      boxShadow: "0 0 0px #fff",
      borderColor: "#0facc4",
    },
  },
  link: {
    marginTop: "50px",
    "@media (max-width: 500px)": {
      marginTop: "0",
    },
  },
  textInput: {
    fontSize: 23,
    color: "#54B5CF",
    fontFamily: "Roboto",
    width: 400,
    margin: "20px 0 -5px 0",
  },
}));

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*   setTimeout(async() => { */
  if (localStorage.getItem("auth")) {
    history.push("/admin/profissional/perfil");
  }
  /*   }, 500); */
  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color={"error"} />
        </Box>
      ) : (
        <></>
      )}
      <Grid container component="main" style={{ marginBottom: "-5%" }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={classes.image}></Grid>
        <Grid item xs={12} sm={9} md={6} elevation={6} square>
          <div className={classes.paper}>
            <Typography className={classes.titulo}>
              LOGIN DA ÁREA DO PROFISSIONAL
            </Typography>

            <Formik
              initialValues={{
                email: "",
                password: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Preencha um email válido")
                  .max(255)
                  .required("Preencha um email válido"),
                password: Yup.string()
                  .max(255)
                  .required("Preencha uma senha válida"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(
                    userSignIn({
                      email: values?.email,
                      password: values.password,
                    })
                  );

                  window.location.reload();
                } catch (error) {
                  const message = error.message || "Ocorreu um erro inexperado";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <p className={classes.textInput}>E-MAIL</p>

                  <TextField
                    type="email"
                    name="email"
                    value={values?.email || ""}
                    error={Boolean(touched?.email && errors?.email)}
                    helperText={touched?.email && errors?.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input} 
                    my={2}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off'
                    }}  
                  />

                  <p className={classes.textInput}>SENHA</p>

                  <TextField
                    type="password"
                    name="password"
                    value={values.password || ""}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input}
                    fullWidth
                    mt={2}
                    mb={8}
                    inputProps={{
    autocomplete: 'new-password',
    form: {
      autocomplete: 'off',
    },
  }}
                  />
                  <Grid className={classes.gridLink}>
                    <Grid item md={4}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.submit}
                      >
                        Login
                      </Button>
                    </Grid>
                    <Grid item md={8} className={classes.link}>
                      <Link
                        onClick={handleClickOpen}
                        target="_blank"
                        className={classes.textLink}
                      >
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>

            <Dialog open={open} onClose={handleClose} hideBackdrop={true}>
              <DialogTitle>Recuperação de senha</DialogTitle>
              <Formik
                initialValues={{
                  emailRecovery: "",
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  emailRecovery: Yup.string()
                    .email("Preencha um email válido")
                    .max(255)
                    .required("Preencha um email válido"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    {
                      const professionalSpec =
                        await ProfessionalsServices.getByMail(
                          values.emailRecovery
                        );
                      let obs = {
                        email: [professionalSpec?.data[0]?.email],
                        subject: "Recuperação de senha",
                        body: `Olá!<br><br>Para criar sua nova senha, clique no botão abaixo:<br> https://minhafono.com.br/recupera/registro/${professionalSpec.data[0].id}/${professionalSpec.data[0].version}<br>Se você não fez essa solicitação, não se preocupe, sua senha não será alterada.`,
                      };

                      await ProfessionalsServices.sendMail(obs).then((map) => { 
 
                        const Toast = Swal.mixin({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: async (toast) => {},
                          willClose: async () => {
                            window.location.reload();
                          },
                        });

                        Toast.fire({
                          icon: "success",
                          title: "E-mail enviado com sucesso!",
                        });
                      });
                    }
                  } catch (error) {
                    const message =
                      error.message || "Ocorreu um erro inexperado";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}
                    <DialogContent>
                      <DialogContentText>
                        Digite o endereço de e-mail da conta Minha Fono que não
                        consegue acessar.
                      </DialogContentText>
                      <TextField
                        type="email"
                        name="emailRecovery"
                        value={values.emailRecovery || ""}
                        error={Boolean(
                          touched.emailRecovery && errors.emailRecovery
                        )}
                        helperText={
                          touched.emailRecovery && errors.emailRecovery
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="chrome-off"
                        autoFocus
                        margin="dense"
                        id="name"
                        label="E-mail"
                        fullWidth
                        variant="standard"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancelar</Button>
                      <Button type="submit" disabled={isSubmitting}>
                        Enviar
                      </Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Dialog>

            <Grid>
              <Grid item md={12}>
                <Link
                  href="/admin/dashboard/registro"
                  className={classes.textLink}
                >
                  Não possui conta? Quero assinar.
                </Link>
              </Grid>
              <Grid item md={8}></Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
