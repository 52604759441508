import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Modal, Box, Button, Link } from '@material-ui/core';
import Image from 'react-bootstrap/Image';
import { Col } from 'react-bootstrap';
import * as PlansServices from "../../services/api/PlansServices";
import * as ProfessionalsServices from "../../services/api/ProfessionalsServices"

import { useUserContext } from "../../providers/useUserContext";
import BannerUm from '../../assets/img/BANNER_JENNY_UEBERBERG.jpeg';
import ImgCirculo from '../../assets/img/faca_parte_da_equipe.png';

import Header from '../../components/header';
import Footer from '../../components/footer';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const useStyles = makeStyles({
	banner: {
		backgroundImage: `url(${BannerUm})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height: 700,
		padding: '150px 0 0 150px',
		'@media (max-width: 500px)': {
			height: 200,
			padding: '10px 0 0 10px'
		}
	},
	botao: {
		backgroundColor: '#54B5CF',
		borderColor: '#54B5CF',
		borderRadius: '25px',
		color: '#fff',
		fontFamily: 'Roboto',
		fontSize: '22px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		height: '50px',
		letterSpacing: '0.085em',
		margin: '30px 0 0 0',
		padding: '10px 20px',
		textAlign: 'center',
		textDecoration: 'none',
		'&:hover': {
			backgroundColor: '#0facc4',
			color: '#fff'
		},
		'@media (max-width: 500px)': {
			backgroundColor: '#54B5CF',
			fontSize: '18px',
			margin: '-30px 0 0 0',
			width: '100%',
		}
	},
	botaoPrimeiro: {
		backgroundColor: '#54B5CF',
		borderColor: '#54B5CF',
		borderRadius: '25px',
		color: '#fff',
		fontFamily: 'Roboto',
		fontSize: '22px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		height: '50px',
		margin: '40px 0 0 0',
		padding: '10px 60px 20px 60px',
		textDecoration: 'none',
		'&:hover': {
			backgroundColor: '#0facc4',
			color: '#fff'
		},
		'@media (max-width: 500px)': {
			backgroundColor: '#54B5CF',
			fontSize: '20px',
			padding: '10px 20px 0 20px',
			margin: '10px 0 0 0'
		}
	},
	container: {
		backgroundColor: '#F8F8FA'
	},
	duasColunas: {
		display: 'flex',
		margin: '5% 8% 0 8%',
		'@media (max-width: 500px)': {
			display: 'grid'
		}
	},
	facaparte: {
		padding: '2% 0 0 10%',
		'@media (max-width: 500px)': {
			padding: '0',
		}
	},
	imagCol: {
		width: '120%',
		'@media (max-width: 500px)': {
			width: '70%',
			margin: '-15px 0 10px 40px'
		}
	},
	segTitulo: {
		fontFamily: 'Montserrat',
		fontSize: '55px',
		fontWeight: 'bold',
		textAlign: 'left',
		color: '#fff',
		margin: '-20px 0 0 0',
		'@media (max-width: 500px)': {
			fontSize: '25px',
			padding: '0px 0px 0px 0px',
			margin: '-5px 0 0 0',
		}
	},
	subtitulo: {
		flex: 1,
		flexDirection: 'row',
		fontFamily: 'Montserrat',
		fontSize: '28px',
		fontWeight: '400',
		textAlign: 'left',
		color: '#fff',
		'@media (max-width: 500px)': {
			fontSize: '15px',
		}
	},
	titulo: {
		fontFamily: 'Montserrat',
		fontSize: '55px',
		fontWeight: 'bold',
		textAlign: 'left',
		color: '#fff',
		'@media (max-width: 500px)': {
			fontSize: '25px',
			padding: '0px 0px 0px 0px',
		}
	},
	textoItalico: {
		fontStyle: 'italic'
	},
	TituloFacaPart: {
		color: '#54B5CF',
		fontFamily: 'Montserrat',
		fontSize: '60px',
		fontWeight: 800,
		margin: '0px',
		'@media (max-width: 500px)': {
			fontFamily: 'Montserrat',
			fontSize: '25px',
			fontWeight: 'bold',
			textAlign: 'center',
			margin: 0,
		}
	},
	textoSessao: {
		borderColor: '#54B5CF',
		borderStyle: 'Solid',
		borderWidth: '0 0  2.5px 0',
		color: '#54B5CF',
		fontFamily: 'Montserrat',
		fontSize: '38px',
		fontWeight: '800',
		margin: '0 0 50px 0',
		padding: '50px 0 10px 0',
		'@media (max-width: 500px)': {
			fontSize: '25px',
			padding: '30px 0px 30px 0px',
			textAlign: 'center',
		}
	},
	textoSub: {
		color: '#666666',
		fontFamily: 'Montserrat',
		fontSize: '30px',
		fontWeight: 400,
		textAlign: 'left',
		'@media (max-width: 500px)': {
			fontSize: '18px',
			padding: '10px 0px 50px 0px',
			textAlign: 'center',
		}
	},
	r: {
		color: '#54B5CF',
		fontFamily: 'Montserrat',
		fontSize: '35px',
		fontWeight: 800,
		textAlign: 'right',
		position: 'relative',
		top: '-15px',
		'@media (max-width: 500px)': {
			fontSize: '20px',
			textAlign: 'center',
		}
	},
	modalButton: {
		marginTop: '20px',
		textDecoration: 'none',
		backgroundColor: '#54B5CF',
		color: '#fff',
		borderRadius: '25px',
		padding: '10px 20px',
		fontSize: '18px',
		'&:hover': {
			backgroundColor: '#0facc4',
			color: '#fff'
		}
	}
});

export default function Home() {

	const [loading, setLoading] = useState(false);
	const [plan, setPlan] = useState(null);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [profile, setProfile] = useState(null);
	const user = useUserContext();

	useEffect(() => {
		if(user){
		(async () => {
			setLoading(true);
			const resp = await PlansServices.get();
			setPlan(resp?.data[0]);
			setLoading(false);
		})();
		}
	}, [user]);



	useEffect(() => {
		if (user) {
			(async () => {
				const resp = await ProfessionalsServices.getProfile();
				setProfile(resp?.data);
			})();
		}
	}, [user]); 

	useEffect(() => {
		if (user && profile) { 
			if (profile?.crfa !== undefined) { 
                handleClose(); 
            } else {
                handleOpen();
            }
		}
	}, [user, profile]);

	const classes = useStyles();
	return (
		<div>
			<div className={classes.container}>
				<Header/>
				<Grid className={classes.banner}>
					<Grid>
						<Typography className={classes.titulo}>
							TERAPIA <span className={classes.textoItalico}>ONLINE</span>
						</Typography>
						<Typography className={classes.segTitulo}>
							E PRESENCIAL
						</Typography>
						<Typography className={classes.subtitulo}>
							Encontre profissionais no conforto<br /> e praticidade de sua casa!
						</Typography>
						<Button className={classes.botaoPrimeiro} href="/profissionais-cards"> ENCONTRAR PROFISSIONAIS </Button>
					</Grid>
				</Grid>
				<Grid>
					<Grid style={{ margin: '1% 8% 0 8%' }}>
						<Col className={classes.colUm}>
							<h3 className={classes.textoSessao}>É UM PROFISSIONAL DA ÁREA?</h3>
						</Col>
					</Grid>
					<Grid className={classes.duasColunas}>
						<Grid item md={3}>
							<Image
								src={ImgCirculo} className={classes.imagCol}
							/>
						</Grid>
						<Grid md={9} className={classes.facaparte}>
							<Grid>
								<Typography
									className={classes.TituloFacaPart}>FAÇA PARTE DA<br /> EQUIPE MINHA FONO<span className={classes.r}>®</span>
								</Typography>
							</Grid>
							<Typography className={classes.textoSub}>
								Profissionais cadastrados recebem acesso a cursos e aplicativos Pró-Fono
							</Typography>
							<Button variant="primary" className={classes.botao} target="_blank" href="https://minhafono.com.br/admin/dashboard/registro">
								QUERO SER ASSINANTE!
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Footer />
			</div>

			<Box sx={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				width: '100%',
				height: '100%',
				display: loading ? 'flex' : 'none',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 1300
			}}>
			</Box>

			{user && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							ALERTA!
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							Sua conta ainda não está visível na busca de profissionais, termine o seu cadastro para que seus dados sejam disponibilizadas na plataforma.
						</Typography>
						<br></br>
						<a href={'/admin/profissional/profissionalEditar/'+ profile?.id } className={classes.modalButton}>Editar perfil</a> 
					</Box>
				</Modal>
			)}
		</div>
	);
}
