/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import * as EbooksViewServices from "../../services/api/EbooksViewServices"; 



import ReaderLogin from "../../components/readerLogin";
 
  export default function BooksReader() {
	const [isScreenshotTaken, setIsScreenshotTaken] = useState(false);
  
	// Obtém a string de consulta da URL
	const searchParams = new URLSearchParams(window.location.search);

	const path = searchParams.get('path');
	const id = searchParams.get('id');
  
	const handleSubmit = async () => { 
		const data = { 
		  ebookId: id
		}; 
		EbooksViewServices.post(data).then(
		  (success) => {
			 
		  },
		  (error) => {
			console.log(error)
		  }
		);
	  };

	useEffect(() => {
	
	  handleSubmit();

	  const handlePrintScreen = (e) => {
		if (e.key === 'PrintScreen') {
		  setIsScreenshotTaken(true);
		  alert("Print bloqueado!!");
		  e.preventDefault();
		}
	  };
  
	  document.addEventListener('keyup', handlePrintScreen);
  
	  return () => {
		document.removeEventListener('keyup', handlePrintScreen);
	  };

	  
	}, []);
  
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const handleLoginSuccess = () => {
	  setIsLoggedIn(true);
	};

	return (
	  <div style={{ position: 'relative', width: '100vw', height: '100vh' }}> 
		   {!isLoggedIn && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white color
            zIndex: 9999 // Ensures this div is in front of all content
          }}
        >
          <ReaderLogin onLoginSuccess={handleLoginSuccess} />
        </div>
      )}
		<iframe
		  src={`https://leitor-pdf.web.app/home?url=${encodeURIComponent(path)}`} // Mantendo a URL original
		  frameBorder="0"
		  style={{ width: '100%', height: '100%' }}
		/>
	  </div>
	);
  }